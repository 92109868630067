import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGsquiggle = ({
  className = 'fill-foreground-scarletTint',
  width = '44',
  height = '7',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
      data-testid='squiggle-svg'
      viewBox='0 0 44 7'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M26.3777 0C29.1476 0 30.6097 1.35127 31.7193 2.37671L31.7536 2.40847C32.8086 3.38321 33.5139 4.00761 35.0529 4.00761C36.592 4.00761 37.2972 3.38321 38.3522 2.40847L38.3865 2.37671C39.4961 1.35127 40.9583 0 43.7281 0V2.99235C42.189 2.99235 41.4838 3.61675 40.4288 4.5915L40.3945 4.62325C39.2849 5.64869 37.8228 6.99997 35.0529 6.99997C32.2831 6.99997 30.8209 5.64869 29.7113 4.62325L29.677 4.5915C28.6221 3.61684 27.9169 2.99247 26.3781 2.99235C24.8391 2.99235 24.1339 3.61679 23.0789 4.59153L23.0445 4.62329C21.9349 5.64873 20.4728 7 17.7029 7C14.9331 7 13.4709 5.64873 12.3614 4.62329L12.327 4.59153C11.272 3.61679 10.5668 2.99239 9.02774 2.99239C7.48868 2.99239 6.78348 3.61679 5.72847 4.59153L5.69411 4.62329C4.58453 5.64873 3.12239 7 0.352539 7V4.00765C1.8916 4.00765 2.5968 3.38325 3.65181 2.4085L3.68617 2.37675C4.79575 1.35131 6.25789 3.47204e-05 9.02774 3.47204e-05C11.7976 3.47204e-05 13.2597 1.35131 14.3693 2.37675L14.4037 2.4085C15.4587 3.38325 16.1639 4.00765 17.7029 4.00765C19.242 4.00765 19.9472 3.38325 21.0022 2.4085L21.0366 2.37675L21.0366 2.3767C22.1461 1.35132 23.6082 0.000138115 26.3777 0Z' />
    </svg>
  )
}

export default SVGsquiggle
