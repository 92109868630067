import * as React from 'react'

interface IHeaderShapeProps {
  id: number
  size: string
}

function HeaderShape({ id, size }: IHeaderShapeProps) {
  switch (id) {
    case 1:
      if (size === 'sm') {
        return (
          <svg
            className='fill-background-low-ochreTint w-sm-full h-sm-auto'
            width='395'
            height='255'
            viewBox='0 0 395 255'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            focusable='false'
            data-testid='header-svg'
          >
            <path d='M395 162.667C309.808 200.597 178.005 245.023 105.7 253.849C53.5904 260.211 19.2073 239.045 0 218.72V0H395V162.667Z' />
          </svg>
        )
      }
      return (
        <svg
          className='fill-background-low-ochreTint'
          width='823'
          height='516'
          viewBox='0 0 823 516'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          focusable='false'
          data-testid='header-svg'
        >
          <path d='M823 209.089C820.219 266.816 777.307 308.948 739.798 332.845C648.821 390.808 360.821 496.88 221.798 513.846C107.33 527.815 50.7976 451.845 39.7976 423.845C35.9421 414.032 20.0504 373.264 0 317.515V0H775.189C800.005 78.1879 819.688 151.752 822.798 193.845C822.88 194.961 822.947 196.071 823 197.176V209.089Z' />
        </svg>
      )
      break
    case 2:
      if (size === 'sm') {
        return (
          <svg
            className='fill-background-low-peach w-sm-full h-sm-auto'
            width='395'
            height='255'
            viewBox='0 0 395 255'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            focusable='false'
            data-testid='header-svg'
          >
            <path d='M395 162.667C309.808 200.597 178.005 245.023 105.7 253.849C53.5904 260.211 19.2073 239.045 0 218.72V0H395V162.667Z' />
          </svg>
        )
      }
      return (
        <svg
          className='fill-background-low-peach'
          width='823'
          height='516'
          viewBox='0 0 823 516'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          focusable='false'
          data-testid='header-svg'
        >
          <path d='M823 209.089C820.219 266.816 777.307 308.948 739.798 332.845C648.821 390.808 360.821 496.88 221.798 513.846C107.33 527.815 50.7976 451.845 39.7976 423.845C35.9421 414.032 20.0504 373.264 0 317.515V0H775.189C800.005 78.1879 819.688 151.752 822.798 193.845C822.88 194.961 822.947 196.071 823 197.176V209.089Z' />
        </svg>
      )
      break
    case 3:
      if (size === 'sm') {
        return (
          <svg
            className='fill-background-low-midblueLite w-sm-full h-sm-auto'
            width='395'
            height='296'
            viewBox='0 0 395 296'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            focusable='false'
            data-testid='header-svg'
          >
            <path d='M395 295.995V0H0V149.943C29.7967 150.381 59.2765 129.31 88.9302 108.115C146.909 66.6736 205.551 24.7579 268.525 142.206C279.516 160.534 289.232 177.931 298.197 193.982C331.828 254.195 354.888 295.482 395 295.995Z' />
          </svg>
        )
      }
      return (
        <svg
          className='fill-background-low-midblueLite float-sm-right'
          width='1076'
          height='351'
          viewBox='0 0 1076 351'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          focusable='false'
          data-testid='header-svg'
        >
          <path d='M1075.57 350.257V0L0 0C0.819668 15.2791 5.2213 32.209 15.0984 46.1838C150.942 282.518 214.09 230.035 293.338 164.173C345.882 120.504 405.502 70.9531 498.081 95.8037C529.151 116.462 549.063 148.227 569.298 180.507C581.404 199.819 593.626 219.316 608.422 236.729C677.022 337.993 742.914 290.911 809.491 243.341C883.656 190.348 958.671 136.748 1039.22 286.936C1052.63 309.277 1064.55 330.537 1075.57 350.257Z' />
        </svg>
      )
      break
    case 4:
      if (size === 'sm') {
        return (
          <svg
            className='fill-background-low-ochreTint w-sm-full h-sm-auto'
            width='395'
            height='296'
            viewBox='0 0 395 296'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            focusable='false'
            data-testid='header-svg'
          >
            <path d='M395 295.995V0H0V149.943C29.7967 150.381 59.2765 129.31 88.9302 108.115C146.909 66.6736 205.551 24.7579 268.525 142.206C279.516 160.534 289.232 177.931 298.197 193.982C331.828 254.195 354.888 295.482 395 295.995Z' />
          </svg>
        )
      }
      return (
        <svg
          className='fill-background-low-ochreTint float-sm-right'
          width='1076'
          height='351'
          viewBox='0 0 1076 351'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          focusable='false'
          data-testid='header-svg'
        >
          <path d='M1075.57 350.257V0H-0.000213115C0.819455 15.2791 5.22109 32.209 15.0982 46.1838C150.941 282.518 214.09 230.035 293.338 164.173C345.881 120.504 405.502 70.9531 498.081 95.8037C529.15 116.462 549.063 148.227 569.298 180.507C581.404 199.819 593.626 219.316 608.422 236.729C677.022 337.993 742.914 290.911 809.49 243.341C883.656 190.348 958.67 136.748 1039.22 286.936C1052.63 309.277 1064.55 330.537 1075.57 350.257Z' />
        </svg>
      )
      break
    case 5:
      if (size === 'sm') {
        return (
          <svg
            className='fill-background-low-midblueLite w-sm-full h-sm-auto'
            width='386'
            height='283'
            viewBox='0 0 386 283'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            focusable='false'
            data-testid='header-svg'
          >
            <path d='M386 277.148V0L0.214916 0C6.03144 43.4195 34.3037 77.1311 71.2323 92.6421C69.2558 97.366 67.5243 102.281 66.0513 107.377C42.28 213.558 145.793 287.685 236.347 243.448C242.411 239.721 248.058 235.687 253.289 231.393C281.423 272.06 334.122 292.52 386 277.148Z' />
          </svg>
        )
      }
      return (
        <svg
          className='fill-background-low-midblueLite float-sm-right'
          width='758'
          height='430'
          viewBox='0 0 758 430'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          focusable='false'
          data-testid='header-svg'
        >
          <path d='M757.354 82.2703V0L0.568546 0C3.97351 71.1106 47.6453 126.783 106.115 151.342C103.216 158.269 100.677 165.478 98.5168 172.95C63.6569 328.662 215.456 437.368 348.251 372.495C357.144 367.029 365.424 361.114 373.096 354.817C419.755 422.264 512.494 451.808 597.454 410.304C684.475 356.818 712.949 260.208 684.902 185.466C722.048 157.367 746.127 120.676 757.354 82.2703Z' />
        </svg>
      )
      break
    case 6:
      if (size === 'sm') {
        return (
          <svg
            className='fill-background-low-peach w-sm-full h-sm-auto'
            width='386'
            height='283'
            viewBox='0 0 386 283'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            focusable='false'
            data-testid='header-svg'
          >
            <path d='M386 277.148V0H0.214916C6.03144 43.4195 34.3037 77.1311 71.2323 92.6421C69.2558 97.366 67.5243 102.281 66.0513 107.377C42.28 213.558 145.793 287.685 236.347 243.448C242.411 239.721 248.058 235.687 253.289 231.393C281.423 272.06 334.122 292.52 386 277.148Z' />
          </svg>
        )
      }
      return (
        <svg
          className='fill-background-low-peach float-sm-right'
          width='758'
          height='430'
          viewBox='0 0 758 430'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          focusable='false'
          data-testid='header-svg'
        >
          <path d='M757.354 82.2703V0H0.568546C3.97351 71.1106 47.6453 126.783 106.115 151.342C103.216 158.269 100.677 165.478 98.5168 172.95C63.6569 328.662 215.456 437.368 348.251 372.495C357.144 367.029 365.424 361.114 373.096 354.817C419.755 422.264 512.494 451.808 597.454 410.304C684.475 356.818 712.949 260.208 684.902 185.466C722.048 157.367 746.127 120.676 757.354 82.2703Z' />
        </svg>
      )
      break
    case 7:
      if (size === 'sm') {
        return (
          <svg
            className='fill-background-low-peach w-sm-full h-sm-auto'
            width='395'
            height='322'
            viewBox='0 0 395 322'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            focusable='false'
            data-testid='header-svg'
          >
            <path d='M395 186.349V0H0V313.472C22.6673 318.73 45.2768 321.748 67.352 321.972C201.955 323.338 327.915 274.273 372.79 219.348C379.742 210.839 387.402 199.637 395 186.349Z' />
          </svg>
        )
      }
      return (
        <svg
          className='fill-background-low-peach'
          width='810'
          height='505'
          viewBox='0 0 810 505'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          focusable='false'
          data-testid='header-svg'
        >
          <path d='M801.768 0H0V432.611C82.9659 475.114 173.096 504.097 257.856 504.959C455.32 506.967 640.105 434.877 705.936 354.175C770.739 274.735 832.494 137.961 801.768 0Z' />
        </svg>
      )
      break
    case 8:
      if (size === 'sm') {
        return (
          <svg
            className='fill-background-low-ochreTint w-sm-full h-sm-auto'
            width='295'
            height='235'
            viewBox='0 0 295 235'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            focusable='false'
            data-testid='header-svg'
          >
            <path d='M296 234.651V0H11.9989C-2.91699 33.1695 -3.39152 72.5267 14.0804 107.682C37.237 154.264 85.3331 180.258 134.16 177.514L134.155 177.52C180.699 176.218 180.863 176.357 213.292 203.885L213.566 204.117C213.582 204.132 213.597 204.148 213.618 204.158C213.671 204.203 213.722 204.247 213.773 204.291C213.824 204.335 213.875 204.379 213.927 204.424L213.932 204.419C236.824 223.949 266.056 234.66 296 234.651Z' />
          </svg>
        )
      }
      return (
        <svg
          className='fill-background-low-ochreTint float-sm-right'
          width='986'
          height='446'
          viewBox='0 0 986 446'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
          focusable='false'
          data-testid='header-svg'
        >
          <path d='M986 404.686V0H0.141514C-1.16238 37.2127 6.51805 75.2876 24.2692 111.005C66.8646 196.69 155.335 244.504 245.149 239.458L245.14 239.468C330.757 237.073 331.058 237.328 390.709 287.965L391.213 288.393C391.242 288.421 391.271 288.449 391.31 288.467C391.405 288.549 391.497 288.629 391.59 288.709C391.685 288.791 391.78 288.873 391.878 288.958L391.887 288.948C445.888 335.018 519.066 354.41 589.594 339.745C644.184 339.219 651.814 345.695 702.796 388.972L703.3 389.401C703.329 389.429 703.358 389.456 703.396 389.474L703.681 389.72L703.965 389.966L703.974 389.956C772.458 448.381 871.786 463.902 957.435 421.461C967.489 416.477 977.018 410.862 986 404.686Z' />
        </svg>
      )
      break
    default:
      return null
  }
}

export default HeaderShape
