import SVGsquiggle from '@/journey/Svgs/utilities/squiggle'
import { RichText } from '@fractal/primitives/atoms/RichText'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useEffect, useState } from 'react'
import styles from './ComponentGenericHeader.module.scss'
import { IComponentGenericHeader } from './ComponentGenericHeader.type'
import HeaderShape from './HeaderShape'

export default function ComponentGenericHeader({
  sys,
  title,
  headerText,
  biggerHeaderText,
  description,
  backgroundImage = true
}: IComponentGenericHeader) {
  const headerTextClass = biggerHeaderText ? 'f-sm-9 f-md-13' : 'f-sm-7 f-md-11'
  const [headerId, setHeaderId] = useState(null)

  // To avoid hydration, we only load it frontend
  useEffect(() => {
    setHeaderId(Math.ceil(Math.random() * 8))
  }, [])

  return (
    <div data-testid='genericHeader'>
      <TrackImpression
        unitName={sys.id || 'genericHeader'}
        componentId={sys.id}
        unitLocation='header'
      />
      {backgroundImage && headerId && (
        <div
          className={`p-sm-absolute top-sm-0 left-sm-0 w-sm-full z-index-n1 o-sm-hidden ${styles.animateShape}`}
        >
          <div className='d-md-none w-sm-full'>
            <HeaderShape id={headerId} size='sm' />
          </div>
          <div className='d-sm-none d-md-block'>
            <HeaderShape id={headerId} size='md' />
          </div>
        </div>
      )}
      <div
        className={`container grid text-center ${styles.container}`}
        data-testid='genericHeader-section'
      >
        <div className='grid-col-sm-12 grid-col-md-10 grid-start-md-2 grid-col-lg-8 grid-start-lg-3'>
          {title ? (
            <>
              <h1
                id='target-skip-to-content'
                className='f-sm-2 f-md-6 text-regular'
              >
                {title}
                <SVGsquiggle className='fill-foreground-scarletTint d-sm-block mx-sm-auto mt-sm-2 mt-md-3' />
              </h1>
              <h2 className={`${headerTextClass} mt-sm-5 mt-md-6`}>
                {headerText}
              </h2>
            </>
          ) : (
            <h1 id='target-skip-to-content' className={headerTextClass}>
              {headerText}
            </h1>
          )}
          {!!description && (
            <div data-testid='genericHeader-description' className='f-sm-3 f-md-5 mt-sm-3 mt-md-4'>
              <RichText richTextContent={description.json} richTextLinks={description.links} unstyled />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
