import { gql } from '@apollo/client'

export interface IEntry {
  /**
   * @deprecated Use {@link typename} instead.
   */
  __typename?: string
  typename: string
  sys: {
    id: string
  }
}

export const entryFragment = gql`
  fragment entryFragment on Entry {
    __typename
    typename: __typename
    sys {
      id
    }
  }
`
